<template>
  <t-body>
    <t-sidebar>
      <TLoading v-if="$fetchState.pending" />
      <div v-else>
        <h3>{{ $t('pages.applicant.near_by.heading') }}</h3>

        <p class="text--grey">
          {{ $t('pages.applicant.near_by.lead') }}
        </p>
        <p class="text--grey">
          {{ $t('pages.applicant.near_by.hint') }}
        </p>
      </div>
    </t-sidebar>

    <TLoading v-show="$fetchState.pending" />
    <t-page v-show="!$fetchState.pending" id="map" />
  </t-body>
</template>

<script>
import Vue from 'vue';
import Organization from '~/models/Organization';
import useSearchMap from '~/composables/useSearchMap';
import TLoading from '~/components/TLoading';

export default Vue.extend({
  components: {
    TLoading,
  },

  setup() {
    const { addResultMarker, resetResultMarkers, buildMap } = useSearchMap();

    return { addResultMarker, resetResultMarkers, buildMap };
  },

  data: () => ({
    results: [],
  }),

  async fetch() {
    this.results = await Organization.$nearBy();
    this.addMarkers();
  },

  mounted() {
    this.buildMap(document.getElementById('map'), {
      zoom: 7,
      center: {
        lat: this.$auth.me.address.position.latitude,
        lng: this.$auth.me.address.position.longitude,
      },
    });

    this.addMarkers();
  },

  methods: {
    addMarkers() {
      if (this.results.length === 0) {
        return;
      }

      this.resetResultMarkers();

      this.results.forEach((result) => {
        this.addResultMarker(result.id, {
          lat: result.address.position.latitude,
          lng: result.address.position.longitude,
        });
      });
    },
  },
});
</script>
